<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas="breadcrumb_data" />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("ips.authorized_ips") }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <div class="dropdown dropdown-inline mr-2">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="svg-icon svg-icon-md">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Design/PenAndRuller.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon--> </span
                  >{{ $t("dt_table.export") }}
                </button>
                <!--begin::Dropdown Menu-->
                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <!--begin::Navigation-->
                  <ul class="navi flex-column navi-hover py-2">
                    <li
                      class="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2"
                    >
                      {{ $t("dt_table.choose_an_option") }}:
                    </li>
                    <li class="navi-item">
                      <a href="" id="printExport" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-print"></i>
                        </span>
                        <span class="navi-text">{{
                          $t("dt_table.print")
                        }}</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="" id="copyExport" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-copy"></i>
                        </span>
                        <span class="navi-text">{{ $t("dt_table.copy") }}</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="" id="excelExport" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-file-excel-o"></i>
                        </span>
                        <span class="navi-text">{{
                          $t("dt_table.excel")
                        }}</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="" id="csvExport" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-file-text-o"></i>
                        </span>
                        <span class="navi-text">{{ $t("dt_table.csv") }}</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="" id="pdfExport" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-file-pdf-o"></i>
                        </span>
                        <span class="navi-text">{{ $t("dt_table.pdf") }}</span>
                      </a>
                    </li>
                  </ul>
                  <!--end::Navigation-->
                </div>
                <!--end::Dropdown Menu-->
              </div>
              <!--end::Dropdown-->
              <!--begin::Button-->
              <router-link
                to="/ips/new"
                class="btn btn-primary font-weight-bolder"
                v-if="checkPermission('crm.ip.create')"
              >
                <span class="svg-icon svg-icon-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle fill="#000000" cx="9" cy="15" r="6" />
                      <path
                        d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon--> </span
                >{{ $t("ips.add_ip") }}
              </router-link>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <div v-if="isLoading">
              <div class="text-center">{{ $t("commons.data_loading") }}...</div>
            </div>
            <table
              class="table table-striped table-bordered collapsed"
              style="width: 100%"
              v-else
              ref="kt_datatable"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("commons.name") }}</th>
                  <th scope="col">{{ $t("commons.employee") }}</th>
                  <th scope="col">{{ $t("commons.ip") }}</th>
                  <th scope="col">{{ $t("commons.date") }}</th>
                  <th scope="col">{{ $t("commons.operations") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in ips" :key="'ips-' + index">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.place }}</td>
                  <td>{{ item.employee }}</td>
                  <td>{{ item.ip }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <router-link
                      v-if="checkPermission('crm.ip.edit')"
                      :to="`/ips/update/${item.id}`"
                      class="btn btn-sm btn-clean btn-icon btn-icon-md"
                      :title="$t('commons.edit')"
                    >
                      <i class="la la-pencil"></i>
                    </router-link>
                    <button
                      v-if="checkPermission('crm.ip.destroy')"
                      type="button"
                      @click="_delete(item.id)"
                      class="btn btn-sm btn-clean btn-icon btn-icon-md"
                      :title="$t('commons.delete')"
                    >
                      <i class="la la-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "Ips",
  data() {
    return {};
  },
  components: {
    Breadcrumb,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.ips.isLoading,
      ips: (state) => state.ips.ips,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
    }),
    breadcrumb_data() {
      return {
        title: this.$t("general_management.title"),
        subtitle: [this.$t("ips.title")],
      };
    },
  },
  methods: {
    ...mapActions("ips", ["GET_IPS", "DELETE_IP"]),
    checkPermission(permission) {
      return this.permissions?.some(
        (item) => String(item.name) === String(permission)
      );
    },
    _delete(id) {
      const vm = this;
      Swal.fire({
        title: this.$t("commons.are_you_sure"),
        text: this.$t("commons.wont_be_able_to_revert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("commons.yes_delete_it"),
        cancelButtonText: this.$t("commons.cancel"),
      }).then(function (result) {
        if (result.value) {
          vm.DELETE_IP(id)
        }
      });
    },
    intializeDtTable() {
      const self = this;
      self.GET_IPS().then(() => {
        self.$nextTick(function () {
          var fileName = "ip-list";
          $(self.$refs["kt_datatable"]).DataTable({
            responsive: true,
            pagingType: "full_numbers",
            dom: "Blfrtip",
            buttons: ["copy", "csv", "excel", "pdf", "print"].map((el) => ({
              extend: el,
              filename: fileName,
            })),
            pageLength: 10,
            initComplete: function () {
              $(".dt-buttons").hide();
            },
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "All"],
            ],
            columnDefs: [
              { orderable: false, targets: [-1] },
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 },
            ],
            order: [[ 4, "desc" ]],
            language: {
              url: `/assets/languages/datatable/${this.lang}.json`,
            },
          });
          $("#printExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-print").trigger("click");
          });
          $("#copyExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-copy").trigger("click");
          });
          $("#excelExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-excel").trigger("click");
          });
          $("#csvExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-csv").trigger("click");
          });
          $("#pdfExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-pdf").trigger("click");
          });
        });
      });
    },
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.ip.index")) {
      this.$router.push("/no-permission");
    }
    this.intializeDtTable();
  },
};
</script>
